var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoading,"variant":"dark","opacity":0.25,"rounded":"sm"}},[_c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.year'),"label-for":"year"}},[_c('validation-provider',{attrs:{"name":"Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"year","options":_vm.LOV.years,"state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.month'),"label-for":"month"}},[_c('validation-provider',{attrs:{"name":"Month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"month","options":_vm.monthOptions,"label":"label","reduce":function (option) { return option.value; },"state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.month),callback:function ($$v) {_vm.$set(_vm.form, "month", $$v)},expression:"form.month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.account'),"label-for":"account"}},[_c('validation-provider',{attrs:{"name":"Account","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"account","options":_vm.LOV.accounts,"label":"label","reduce":function (option) { return option.value; },"state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.account_id),callback:function ($$v) {_vm.$set(_vm.form, "account_id", $$v)},expression:"form.account_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.transactionsBeginningBalance.singular.openAmount'),"label-for":"open_amount"}},[_c('validation-provider',{attrs:{"name":"Open Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"open_amount","options":_vm.numeric,"state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.open_amount),callback:function ($$v) {_vm.$set(_vm.form, "open_amount", _vm._n($$v))},expression:"form.open_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.office'),"label-for":"office_id"}},[_c('validation-provider',{attrs:{"name":"Office","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"office_id","options":_vm.LOV.offices,"label":"label","reduce":function (option) { return option.value; },"state":errors.length > 0 ? false : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.office_id),callback:function ($$v) {_vm.$set(_vm.form, "office_id", $$v)},expression:"form.office_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":"outline-secondary","to":{ name: 'apps-transactions-beginning-balance-list'}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.changeToEdit'))+" ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }