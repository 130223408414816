<template>
  <b-overlay
    :show="showLoading"
    variant="dark"
    :opacity="0.25"
    rounded="sm"
  >
    <b-card no-body class="p-3">
      <b-row align-h="center">
        <b-col>
          <!-- ValidationObserver from VeeValidate, similar to office/Form.vue -->
          <validation-observer ref="formRef">
            <b-form @submit.prevent>
              <b-row>
                <!-- Year -->
                <b-col md="6">
                  <b-form-group :label="$t('globalSingular.year')" label-for="year">
                    <validation-provider
                      #default="{ errors }"
                      name="Year"
                      rules="required"
                    >
                      <v-select
                        id="year"
                        v-model="form.year"
                        :options="LOV.years"
                        class="select-size-sm"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Month -->
                <b-col md="6">
                  <b-form-group :label="$t('globalSingular.month')" label-for="month">
                    <validation-provider
                      #default="{ errors }"
                      name="Month"
                      rules="required"
                    >
                      <v-select
                        id="month"
                        v-model="form.month"
                        :options="monthOptions"
                        label="label"
                        :reduce="option => option.value"
                        class="select-size-sm"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Account -->
                <b-col md="6">
                  <b-form-group :label="$t('globalSingular.account')" label-for="account">
                    <validation-provider
                      #default="{ errors }"
                      name="Account"
                      rules="required"
                    >
                      <v-select
                        id="account"
                        v-model="form.account_id"
                        :options="LOV.accounts"
                        label="label"
                        :reduce="option => option.value"
                        class="select-size-sm"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Amount -->
                <b-col md="6">
                  <b-form-group :label="$t('apps.transactionsBeginningBalance.singular.openAmount')" label-for="open_amount">
                    <validation-provider
                      #default="{ errors }"
                      name="Open Amount"
                      rules="required"
                    >
                      <cleave
                        id="open_amount"
                        v-model.number="form.open_amount"
                        class="form-control"
                        :options="numeric"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Office -->
                <b-col md="6">
                  <b-form-group :label="$t('globalSingular.office')" label-for="office_id">
                    <validation-provider
                      #default="{ errors }"
                      name="Office"
                      rules="required"
                    >
                      <v-select
                        id="office_id"
                        v-model="form.office_id"
                        :options="LOV.offices"
                        label="label"
                        :reduce="option => option.value"
                        class="select-size-sm"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Action Buttons -->
              <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  variant="outline-secondary"
                  :to="{ name: 'apps-transactions-beginning-balance-list'}"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  {{ $t('globalActions.backToList') }}
                </b-button>

                <!-- If in preview mode, show delete, else show create/update -->
                <b-button
                  v-if="actions.isPreview"
                  class="mb-sm-1 mb-md-0"
                  variant="outline-danger"
                  @click="handleDelete"
                >
                  <feather-icon icon="Trash2Icon" />
                  {{ $t('globalActions.delete') }}
                </b-button>

                <b-button
                  v-if="!actions.isPreview"
                  variant="primary"
                  type="submit"
                  @click="handleSubmit"
                >
                  <feather-icon icon="SaveIcon" />
                  {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
                </b-button>

                <b-button
                  v-else
                  variant="outline-primary"
                  @click="changeToEdit"
                >
                  <feather-icon icon="EditIcon" />
                  {{ $t('globalActions.changeToEdit') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  VBToggle,
  BOverlay
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useFormResource from '@/comp-functions/useFormResource'
import { getMonths, getYears } from '@/utils/index'
import Cleave from 'vue-cleave-component'
import { required } from '@validations'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
    BOverlay,
  },


  directives: {
    'b-toggle': VBToggle,
    Ripple
  },


  setup() {
    const {
      paramsId,
      formRef,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({
      url: 'master/bl-account',
      localeContextPath: 'apps.transactionsBeginningBalance.singular.beginningBalance',
      redirectPathName: 'apps-transactions-beginning-balance'
    })

    const form = ref({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      account_id: null,
      office_id: null,
      open_amount: 0,
    })

    const LOV = ref({
      accounts: [],
      offices: [],
      years: getYears(),
    })

    const monthOptions = getMonths()

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    const showLoading = ref(true)
    const isCanceled = ref(false)

    const loadOptions = async () => {
      try {
        const accountResponse = await get({ url: 'master/accounts' })
        LOV.value.accounts = (accountResponse || []).map(item => ({
          value: item.id,
          label: item.name || item.label
        }))

        const officeResponse = await get({ url: 'value/getBranch' })
        LOV.value.offices = (officeResponse || []).map(item => ({
          value: item.id,
          label: item.brand || item.label
        }))
      } catch (error) {
        console.error('Error loading options:', error)
        LOV.value.accounts = []
        LOV.value.offices = []
      }
    }

    const getDataPreview = async () => {
      const data = await show()
      form.value.year = data.year
      form.value.month = data.month
      form.value.account_id = data.account
      form.value.open_amount = data.open_amount
      form.value.office_id = data.office_id
    }

    onMounted(async () => {
      showLoading.value = true

      await loadOptions()

      if (paramsId) {
        actions.value.isPreview = true
        await getDataPreview()
      }

      showLoading.value = false
    })

    return {
      formRef,
      paramsId,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      monthOptions,
      form,
      LOV,
      loadOptions,
      getDataPreview,
      numeric,
      required,
      showLoading,
      isCanceled,
    }
  },


  methods: {
    async handleDelete() {
      this.showLoading = true
      await this.destroy(this.$swal)
      this.showLoading = false
    },

    async handleSubmit() {
      this.showLoading = true
      const isValid = await this.formRef.validate()

      if (isValid) {
        await this.store({
          $swal: this.$swal,
          data: this.form,
          onCancelCallback: () => {
            this.isCanceled = true
          }
        }).catch((error) => {
          console.error(`Request error: ${error.message || error}`)
        }).finally(() => {
          this.showLoading = false
        })
      } else {
        this.showLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
